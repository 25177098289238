import { default as loginQOmzWLy7UJMeta } from "/vercel/path0/pages/(public)/(auth)/login.vue?macro=true";
import { default as _91uuid_93pG9a0p4KV0Meta } from "/vercel/path0/pages/(public)/(playback)/[uuid].vue?macro=true";
import { default as _91token_93ijiOsAdCeuMeta } from "/vercel/path0/pages/(public)/(room)/[token].vue?macro=true";
import { default as privacy_45policyQl0qlywOJTMeta } from "/vercel/path0/pages/privacy-policy.vue?macro=true";
import { default as privacy_45policyOFOeCYe2FuMeta } from "/vercel/path0/pages/(public)/privacy-policy.vue?macro=true";
import { default as terms_45and_45conditions0ynQwIk1xRMeta } from "/vercel/path0/pages/terms-and-conditions.vue?macro=true";
import { default as terms_45and_45conditionsDC2KMnedMxMeta } from "/vercel/path0/pages/(public)/terms-and-conditions.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as job_45postszzuW0magrlMeta } from "/vercel/path0/pages/job-posts.vue?macro=true";
import { default as _91id_93haGaVvTWZyMeta } from "/vercel/path0/pages/jobs/[id].vue?macro=true";
import { default as playbacksMl2V2tBcd2Meta } from "/vercel/path0/pages/playbacks.vue?macro=true";
import { default as playgroundN1k3r6VoPnMeta } from "/vercel/path0/pages/playground.vue?macro=true";
import { default as api_45keysSRqQV0DdOhMeta } from "/vercel/path0/pages/settings/api-keys.vue?macro=true";
import { default as integrationsb3siMJQWWvMeta } from "/vercel/path0/pages/settings/integrations.vue?macro=true";
import { default as organisationlzKdSx67HeMeta } from "/vercel/path0/pages/settings/organisation.vue?macro=true";
import { default as preferencesiZk4GzZcfPMeta } from "/vercel/path0/pages/settings/preferences.vue?macro=true";
import { default as usersh4j5PhVKwFMeta } from "/vercel/path0/pages/settings/users.vue?macro=true";
import { default as webhooksFdHCHKDzoDMeta } from "/vercel/path0/pages/settings/webhooks.vue?macro=true";
export default [
  {
    name: "login",
    path: "/login",
    meta: { ...(loginQOmzWLy7UJMeta || {}), ...{"middleware":["guest"]} },
    component: () => import("/vercel/path0/pages/(public)/(auth)/login.vue")
  },
  {
    name: "uuid",
    path: "/p/:uuid",
    meta: { ...(_91uuid_93pG9a0p4KV0Meta || {}), ...{"middleware":["auth"]} },
    component: () => import("/vercel/path0/pages/(public)/(playback)/[uuid].vue")
  },
  {
    name: "token",
    path: "/r/:token",
    meta: { ...(_91token_93ijiOsAdCeuMeta || {}), ...{"middleware":["auth"]} },
    component: () => import("/vercel/path0/pages/(public)/(room)/[token].vue")
  },
  {
    name: privacy_45policyOFOeCYe2FuMeta?.name,
    path: "/privacy-policy",
    meta: { ...(privacy_45policyOFOeCYe2FuMeta || {}), ...{"middleware":["auth"]} },
    component: () => import("/vercel/path0/pages/(public)/privacy-policy.vue"),
    children: [
  {
    name: "privacy-policy",
    path: "",
    meta: { ...(privacy_45policyQl0qlywOJTMeta || {}), ...{"middleware":["guest"]} },
    component: () => import("/vercel/path0/pages/privacy-policy.vue")
  }
]
  },
  {
    name: terms_45and_45conditionsDC2KMnedMxMeta?.name,
    path: "/terms-and-conditions",
    meta: { ...(terms_45and_45conditionsDC2KMnedMxMeta || {}), ...{"middleware":["auth"]} },
    component: () => import("/vercel/path0/pages/(public)/terms-and-conditions.vue"),
    children: [
  {
    name: "terms-and-conditions",
    path: "",
    meta: { ...(terms_45and_45conditions0ynQwIk1xRMeta || {}), ...{"middleware":["guest"]} },
    component: () => import("/vercel/path0/pages/terms-and-conditions.vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: { ...(indexe9Brt5DfdhMeta || {}), ...{"middleware":["auth"]} },
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "job-posts",
    path: "/job-posts",
    meta: { ...(job_45postszzuW0magrlMeta || {}), ...{"middleware":["auth"]} },
    component: () => import("/vercel/path0/pages/job-posts.vue")
  },
  {
    name: "jobs-id",
    path: "/j/:id",
    meta: { ...(_91id_93haGaVvTWZyMeta || {}), ...{"middleware":["guest"]} },
    component: () => import("/vercel/path0/pages/jobs/[id].vue")
  },
  {
    name: "playbacks",
    path: "/playbacks",
    meta: { ...(playbacksMl2V2tBcd2Meta || {}), ...{"middleware":["auth"]} },
    component: () => import("/vercel/path0/pages/playbacks.vue")
  },
  {
    name: "playground",
    path: "/playground",
    meta: {"middleware":["auth"]},
    component: () => import("/vercel/path0/pages/playground.vue")
  },
  {
    name: "settings-api-keys",
    path: "/settings/api-keys",
    meta: { ...(api_45keysSRqQV0DdOhMeta || {}), ...{"middleware":["auth"]} },
    component: () => import("/vercel/path0/pages/settings/api-keys.vue")
  },
  {
    name: "settings-integrations",
    path: "/settings/integrations",
    meta: { ...(integrationsb3siMJQWWvMeta || {}), ...{"middleware":["auth"]} },
    component: () => import("/vercel/path0/pages/settings/integrations.vue")
  },
  {
    name: "settings-organisation",
    path: "/settings/organisation",
    meta: { ...(organisationlzKdSx67HeMeta || {}), ...{"middleware":["auth"]} },
    component: () => import("/vercel/path0/pages/settings/organisation.vue")
  },
  {
    name: "settings-preferences",
    path: "/settings/preferences",
    meta: { ...(preferencesiZk4GzZcfPMeta || {}), ...{"middleware":["auth"]} },
    component: () => import("/vercel/path0/pages/settings/preferences.vue")
  },
  {
    name: "settings-users",
    path: "/settings/users",
    meta: { ...(usersh4j5PhVKwFMeta || {}), ...{"middleware":["auth"]} },
    component: () => import("/vercel/path0/pages/settings/users.vue")
  },
  {
    name: "settings-webhooks",
    path: "/settings/webhooks",
    meta: { ...(webhooksFdHCHKDzoDMeta || {}), ...{"middleware":["auth"]} },
    component: () => import("/vercel/path0/pages/settings/webhooks.vue")
  }
]