<template>
  <svg width="431" height="430" viewBox="0 50 431 300" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M332.202 210.872H359.808C362.474 205.368 362.99 199.09 363.162 192.984C363.334 187.394 363.162 181.718 361.442 176.386C356.884 162.282 342.092 154.112 327.644 157.81C325.924 155.832 323.602 154.37 321.108 153.768C318.614 153.252 311.39 153.94 311.304 156.434C311.304 157.208 311.562 157.982 311.992 158.67C314.486 163.314 318.356 167.184 321.452 171.484C328.762 181.46 330.052 197.198 332.202 210.872Z" fill="white" />
    <path d="M359.808 211.56H332.202C331.858 211.56 331.6 211.302 331.6 211.044C331.256 208.894 330.912 206.744 330.654 204.508C328.934 192.554 327.128 180.17 321.108 171.914C319.99 170.366 318.786 168.904 317.582 167.528C315.346 164.948 313.282 162.11 311.562 159.1C311.132 158.326 310.788 157.466 310.788 156.606C310.788 155.918 311.218 155.316 311.734 154.886C313.884 153.252 319.216 152.822 321.366 153.338C323.946 153.94 326.268 155.316 327.988 157.294C334.524 155.746 341.49 156.52 347.51 159.444C354.476 162.884 359.722 168.99 362.13 176.3C363.85 181.718 364.022 187.566 363.85 193.07C363.678 199.176 363.162 205.626 360.41 211.216C360.324 211.388 360.066 211.56 359.808 211.56ZM332.804 210.27H359.464C361.958 204.938 362.388 198.832 362.56 192.984C362.732 186.706 362.474 181.546 360.926 176.644C356.454 162.798 342.006 154.886 327.902 158.498C327.644 158.584 327.472 158.498 327.3 158.326C325.666 156.434 323.516 155.058 321.108 154.456C319.302 154.112 314.314 154.456 312.594 155.832C312.164 156.176 312.164 156.52 312.078 156.606C312.164 157.294 312.336 157.896 312.68 158.498C314.4 161.422 316.378 164.174 318.614 166.754C319.818 168.216 321.022 169.678 322.14 171.226C328.332 179.74 330.224 192.296 331.944 204.422C332.202 206.314 332.46 208.292 332.804 210.27Z" fill="#020B23" />
    <path d="M362.474 155.488C365.914 152.736 369.268 149.726 373.31 148.006C373.654 147.834 373.998 147.748 374.342 147.748C374.686 147.834 374.944 148.006 375.202 148.35C376.234 149.64 376.492 151.36 376.062 152.908C375.632 154.456 374.858 155.918 373.826 157.122C371.246 160.648 368.15 163.83 364.796 166.582C362.474 168.388 359.894 170.108 356.97 170.71C353.014 171.484 343.898 167.614 347.94 162.282C349.058 160.734 351.724 161.078 353.358 160.562C356.712 159.444 359.808 157.724 362.474 155.488Z" fill="white" />
    <path d="M355.852 171.398C352.842 171.398 348.628 169.764 347.08 167.184C346.306 165.98 345.79 164.088 347.51 161.852C348.456 160.562 350.176 160.39 351.638 160.218C352.154 160.132 352.756 160.046 353.272 159.96C356.54 158.928 359.464 157.208 362.044 154.972L363.936 153.424C366.688 151.188 369.612 148.78 372.966 147.318C373.396 147.06 373.912 146.974 374.428 147.06C374.944 147.146 375.374 147.404 375.718 147.834C376.922 149.296 377.266 151.188 376.75 152.994C376.234 154.628 375.46 156.09 374.428 157.466C371.762 161.078 368.752 164.26 365.226 167.012C363.162 168.646 360.41 170.624 357.142 171.226C356.712 171.398 356.282 171.398 355.852 171.398ZM362.904 156.004C360.152 158.326 357.056 160.046 353.702 161.164C353.1 161.336 352.498 161.422 351.896 161.508C350.606 161.68 349.23 161.852 348.628 162.712C347.596 164.088 347.51 165.378 348.198 166.582C349.746 168.99 354.39 170.624 356.97 170.108C359.98 169.506 362.56 167.614 364.538 166.152C367.892 163.486 370.902 160.304 373.482 156.778C374.428 155.574 375.202 154.284 375.632 152.822C376.062 151.446 375.804 149.984 374.858 148.866C374.686 148.694 374.514 148.608 374.342 148.522C374.084 148.522 373.826 148.608 373.654 148.694C370.472 150.07 367.634 152.392 364.882 154.542C364.194 154.972 363.506 155.488 362.904 156.004Z" fill="#020B23" />
    <path d="M163.298 127.71C161.234 118.508 158.482 109.392 157.708 99.932C156.934 90.472 158.138 80.582 163.47 72.756C165.62 69.66 168.544 66.822 172.156 65.79C175.338 64.844 180.756 66.65 180.756 66.65C183.336 64.758 186.088 62.952 188.926 61.318C194.946 58.308 201.74 57.276 208.362 56.588C217.822 55.642 228.744 56.158 234.764 63.554C239.15 68.972 239.58 76.454 239.752 83.42L240.182 101.308" fill="white" />
    <path d="M163.298 128.398C162.954 128.398 162.696 128.226 162.61 127.882C162.094 125.474 161.492 123.066 160.89 120.658C159.256 113.95 157.536 107.07 156.934 100.018C156.246 92.364 156.762 81.356 162.868 72.412C165.448 68.714 168.544 66.134 171.898 65.188C174.822 64.328 179.294 65.532 180.584 65.876C183.164 63.984 185.83 62.264 188.582 60.716C194.946 57.534 202.084 56.502 208.276 55.9C221.434 54.61 230.292 56.932 235.28 63.038C240.01 68.886 240.268 77.228 240.44 83.334L240.87 101.222C240.87 101.566 240.612 101.91 240.182 101.91C239.838 101.91 239.494 101.652 239.494 101.222L238.978 83.42C238.806 76.024 238.29 68.972 234.162 63.898C229.432 58.136 221.004 55.986 208.276 57.19C202.256 57.792 195.204 58.824 189.098 61.834C186.346 63.468 183.68 65.188 181.1 67.08C180.928 67.166 180.67 67.252 180.498 67.166C180.412 67.166 175.252 65.446 172.328 66.392C168.458 67.51 165.706 70.692 163.986 73.1C158.138 81.7 157.622 92.364 158.31 99.846C158.912 106.726 160.546 113.606 162.18 120.228C162.782 122.636 163.384 125.044 163.9 127.452C163.986 127.796 163.728 128.14 163.384 128.226L163.298 128.398Z" fill="#020B23" />
    <path d="M265.81 373.756C259.446 255.592 269.508 211.56 264.52 199.434C252.566 170.796 182.734 161.422 182.734 161.422C182.734 161.422 107.14 161.078 90.37 201.068C86.156 211.13 57.002 280.962 54.164 324.048C52.702 345.548 75.148 341.248 94.584 343.054V373.842L265.81 373.756Z" style="fill: rgb(var(--una-primary-600))" />
    <path d="M218.768 214.656L213.006 222.568L235.366 316.48L222.122 343.226L202.686 316.48L205.438 223.256L197.096 213.624L209.136 204.164L218.768 214.656Z" fill="white" />
    <path d="M222.122 343.828C221.95 343.828 221.692 343.742 221.606 343.57L202.17 316.91C202.084 316.824 202.084 316.652 202.084 316.48L204.836 223.514L196.666 214.054C196.58 213.882 196.494 213.71 196.494 213.538C196.494 213.366 196.58 213.194 196.752 213.108L208.792 203.562C209.05 203.39 209.394 203.39 209.652 203.648L219.284 214.226C219.456 214.484 219.456 214.828 219.284 215.086L213.78 222.74L236.054 316.394C236.054 316.566 236.054 316.738 235.968 316.824L222.724 343.484C222.638 343.656 222.466 343.828 222.122 343.828ZM203.374 316.308L222.036 341.936L234.678 316.48L212.318 222.74C212.318 222.568 212.318 222.31 212.404 222.224L217.822 214.828L208.964 205.11L197.956 213.796L205.868 222.912C205.954 222.998 206.04 223.17 206.04 223.342L203.374 316.308Z" fill="#020B23" />
    <path d="M225.906 167.184L228.916 186.964C228.916 186.964 224.444 208.378 202.686 203.39C189.786 200.38 179.036 191.694 173.36 179.74L170.952 133.816C170.952 133.816 186.948 125.13 181.874 90.214C181.874 90.214 206.126 101.91 238.29 97.524C238.29 97.524 246.03 133.472 243.536 150.242C241.042 167.012 225.906 167.184 225.906 167.184Z" fill="white" />
    <path d="M209.05 204.766C206.9 204.766 204.75 204.508 202.6 203.992C180.756 198.918 172.844 180.17 172.758 179.998C172.758 179.912 172.672 179.826 172.672 179.826L170.264 133.902C170.264 133.644 170.35 133.386 170.608 133.3C170.78 133.214 186.174 124.27 181.186 90.386C181.186 90.128 181.272 89.87 181.444 89.784C181.616 89.612 181.874 89.612 182.132 89.698C182.39 89.784 206.556 101.308 238.204 96.922C238.548 96.836 238.892 97.094 238.978 97.438C239.322 98.9 246.718 133.644 244.31 150.414C242.16 165.378 229.862 167.528 226.68 167.872L229.604 186.878C229.604 186.964 229.604 187.05 229.604 187.136C229.518 187.48 227.454 196.854 219.714 201.842C216.532 203.82 212.834 204.852 209.05 204.766ZM174.048 179.568C174.65 181.03 182.562 197.972 202.858 202.702C209.136 204.164 214.554 203.476 218.94 200.724C225.82 196.338 227.97 187.996 228.228 186.964L225.218 167.356C225.218 167.184 225.218 167.012 225.39 166.84C225.476 166.668 225.734 166.582 225.906 166.582C226.508 166.582 240.612 166.238 242.934 150.242C245.17 134.934 238.806 103.114 237.774 98.298C209.824 101.91 187.808 93.482 182.648 91.332C186.862 122.808 173.962 132.698 171.554 134.246L174.048 179.568Z" fill="#020B23" />
    <path d="M214.726 118.508C214.726 118.508 225.046 137.428 221.95 140.266C218.854 143.104 211.028 140.352 211.028 140.352" fill="white" />
    <path d="M217.65 142.244C215.328 142.158 213.006 141.814 210.856 141.04C210.512 140.954 210.254 140.61 210.34 140.266C210.426 139.922 210.77 139.664 211.114 139.75C211.2 139.75 211.2 139.75 211.286 139.836C211.372 139.836 218.682 142.416 221.52 139.922C223.412 138.202 218.338 126.592 214.21 118.938C214.038 118.594 214.124 118.25 214.468 118.078C214.812 117.906 215.156 117.992 215.328 118.336C217.048 121.604 225.734 137.858 222.38 140.868C221.004 141.9 219.37 142.33 217.65 142.244Z" fill="#020B23" />
    <path d="M199.018 118.758C200.204 118.708 201.074 116.475 200.96 113.77C200.846 111.065 199.792 108.913 198.606 108.963C197.419 109.013 196.55 111.246 196.664 113.951C196.777 116.656 197.831 118.808 199.018 118.758Z" fill="#020B23" />
    <path d="M230.493 120.051C231.679 120.002 232.549 117.768 232.435 115.063C232.321 112.359 231.267 110.206 230.081 110.256C228.894 110.306 228.025 112.539 228.139 115.244C228.253 117.949 229.307 120.101 230.493 120.051Z" fill="#020B23" />
    <path d="M184.884 124.872C182.992 121.604 180.412 118.68 177.402 116.358C175.854 114.982 173.876 114.122 171.812 113.864C170.092 113.864 168.458 114.38 166.996 115.24C162.696 117.82 159.686 122.034 158.654 126.936C158.138 129.172 158.224 131.494 158.912 133.73C159.944 137.17 162.61 139.922 165.706 141.9C168.802 143.706 172.156 145.168 175.596 146.2" fill="white" />
    <path d="M175.596 146.974C175.51 146.974 175.424 146.974 175.424 146.974C171.898 145.942 168.544 144.48 165.362 142.588C162.094 140.696 159.6 137.686 158.31 134.074C157.622 131.752 157.536 129.344 158.052 126.936C159.084 121.862 162.266 117.39 166.738 114.724C168.286 113.778 170.092 113.262 171.898 113.176C174.306 113.262 176.456 114.724 177.832 115.756C180.928 118.164 183.508 121.088 185.486 124.442C185.658 124.786 185.572 125.13 185.314 125.388C184.97 125.56 184.626 125.474 184.368 125.216V125.13C182.476 121.948 179.982 119.11 177.058 116.874C175.854 115.928 173.962 114.638 171.898 114.552C170.35 114.552 168.802 115.068 167.512 115.928C163.384 118.336 160.374 122.464 159.428 127.194C158.998 129.344 159.084 131.58 159.686 133.644C160.89 136.912 163.212 139.664 166.222 141.384C169.318 143.19 172.586 144.566 176.026 145.598C176.37 145.684 176.542 146.114 176.456 146.458C176.198 146.802 175.94 146.974 175.596 146.974Z" fill="#020B23" />
    <path d="M225.906 167.184C225.906 167.184 208.706 170.968 189.442 161.164C189.442 161.164 208.018 189.716 228.744 185.76L225.906 167.184Z" fill="#020B23" />
    <path d="M224.788 186.792C217.22 186.792 209.05 182.922 200.88 175.44C196.408 171.226 192.366 166.582 188.926 161.508C188.754 161.25 188.754 160.906 189.012 160.734C189.184 160.476 189.528 160.476 189.786 160.562C208.62 170.108 225.648 166.582 225.82 166.496C225.992 166.496 226.164 166.496 226.336 166.582C226.508 166.668 226.594 166.84 226.594 167.012L229.432 185.588C229.518 185.932 229.26 186.276 228.916 186.362C227.54 186.62 226.164 186.792 224.788 186.792ZM191.42 162.884C196.064 169.162 211.458 187.996 228.056 185.244L225.39 167.958C213.866 169.506 201.998 167.786 191.42 162.884Z" fill="#020B23" />
    <path d="M226.594 146.286C226.594 146.286 204.406 163.486 195.634 130.118Z" fill="white" />
    <path d="M222.567 148.016C221.395 147.419 220.129 147.008 218.831 146.813C213.328 146.031 207.395 149.146 201.186 156.158C200.999 156.373 200.997 156.683 201.212 156.87C201.427 157.057 201.737 157.059 201.924 156.844C207.914 150.109 213.568 147.085 218.638 147.802C225.44 148.779 229.131 156.325 229.161 156.418C229.284 156.636 229.592 156.793 229.81 156.671C230.027 156.548 230.184 156.24 230.062 156.023C229.971 155.743 227.501 150.527 222.567 148.016Z" fill="#020B23" />
    <path d="M322.914 159.272C322.914 159.272 338.308 164.948 340.028 181.976Z" fill="white" />
    <path d="M340.028 182.578C339.684 182.578 339.426 182.32 339.426 181.976C337.792 165.636 322.914 159.874 322.742 159.874C322.398 159.788 322.226 159.358 322.312 159.014C322.398 158.67 322.828 158.498 323.172 158.67C323.344 158.756 338.996 164.69 340.716 181.89C340.716 182.234 340.458 182.578 340.114 182.578H340.028Z" fill="#020B23" />
    <path d="M253.77 186.104C253.77 186.104 318.012 246.39 318.7 242.52C322.914 217.752 326.354 196.682 326.354 196.682L368.322 198.402C368.322 198.402 368.322 316.48 340.114 319.318C318.7 321.468 244.224 251.722 244.224 251.722L253.77 186.104Z" style="fill: rgb(var(--una-primary-600))" />
    <path d="M264.52 348.73C264.176 348.73 263.918 348.472 263.832 348.128L251.878 207.69C251.878 207.346 252.136 207.002 252.48 207.002C252.824 207.002 253.168 207.26 253.168 207.604L265.122 348.042C265.208 348.386 264.95 348.73 264.52 348.73Z" fill="#020B23" />
    <path d="M166.48 345.806L83.4039 343.14C83.0599 343.14 82.7159 342.796 82.8019 342.452C82.8019 342.108 83.1459 341.85 83.4899 341.764L165.878 344.43L168.2 300.312L110.752 293.518C110.58 293.518 110.408 293.432 110.322 293.26C110.236 293.088 110.15 292.916 110.236 292.744C117.718 261.096 128.812 213.28 128.726 211.044C128.64 210.7 128.898 210.356 129.242 210.27C129.586 210.184 129.93 210.442 130.016 210.786C130.532 212.764 115.224 277.436 111.698 292.314L169.06 299.108C169.404 299.108 169.662 299.452 169.662 299.796L167.254 345.204C167.168 345.548 166.824 345.806 166.48 345.806Z" fill="#020B23" />
    <path d="M313.884 263.246H313.712C313.368 263.16 313.11 262.816 313.196 262.472L324.72 207.518C324.806 207.174 325.15 206.916 325.494 207.002C325.838 207.088 326.096 207.432 326.01 207.776L314.486 262.73C314.4 262.988 314.142 263.246 313.884 263.246Z" fill="#020B23" />
    <path d="M174.134 230.308C173.618 230.308 173.102 230.136 172.758 229.792C166.652 225.062 156.16 183.696 156.762 178.966C157.45 173.72 166.05 166.582 166.394 166.238C166.652 165.98 167.082 166.066 167.34 166.324C167.598 166.582 167.512 167.012 167.254 167.27C167.168 167.356 158.654 174.408 158.052 179.138C157.45 183.696 167.856 224.46 173.532 228.76C173.79 229.018 174.048 229.104 174.22 229.018C179.38 226.954 193.312 207.518 193.484 207.346C193.742 207.088 194.172 207.002 194.43 207.26C194.688 207.432 194.774 207.862 194.602 208.12C194 208.98 180.326 228.072 174.822 230.222C174.564 230.222 174.306 230.308 174.134 230.308Z" fill="#020B23" />
    <path d="M234.248 222.912H233.99C229.69 222.482 222.724 208.034 221.95 206.4C221.778 206.056 221.95 205.712 222.294 205.54C222.638 205.368 222.982 205.54 223.154 205.884C225.992 211.818 231.496 221.364 234.162 221.622C234.42 221.622 234.764 221.536 234.936 221.278C238.204 217.064 242.59 193.672 239.494 182.148C239.408 181.804 239.58 181.46 239.924 181.374C240.268 181.288 240.612 181.46 240.698 181.804C243.966 193.93 239.322 217.58 235.882 222.052C235.538 222.568 234.936 222.912 234.248 222.912Z" fill="#020B23" />
    <path d="M327.644 151.962L327.386 152.392C326.096 154.456 323.344 155.144 321.194 153.768L291.18 134.848L296.254 126.85L326.268 145.77C328.332 147.146 328.934 149.898 327.644 151.962Z" fill="white" />
    <path d="M323.516 155.144C322.57 155.144 321.624 154.886 320.764 154.37L290.75 135.45C290.578 135.364 290.492 135.192 290.492 135.02C290.492 134.848 290.492 134.676 290.578 134.504L295.652 126.506C295.824 126.248 296.254 126.162 296.512 126.334L326.526 145.254C328.934 146.716 329.622 149.898 328.16 152.306L327.902 152.822C326.87 154.198 325.322 155.144 323.516 155.144ZM292.04 134.676L321.452 153.252C323.258 154.37 325.58 153.854 326.698 152.048L326.956 151.618C328.074 149.812 327.558 147.49 325.752 146.372L296.34 127.796L292.04 134.676Z" fill="#020B23" />
    <path d="M293.071 121.424L284.904 134.372L292.178 138.96L300.345 126.012L293.071 121.424Z" fill="white" />
    <path d="M292.126 139.578C292.04 139.578 291.868 139.578 291.782 139.492L284.472 134.934C284.214 134.762 284.128 134.332 284.3 134.074L292.47 121.088C292.642 120.83 293.072 120.744 293.33 120.916L300.64 125.474C300.812 125.56 300.898 125.732 300.898 125.904C300.898 126.076 300.898 126.248 300.812 126.42L292.642 139.406C292.556 139.578 292.384 139.664 292.212 139.664L292.126 139.578ZM285.762 134.16L291.954 138.116L299.436 126.248L293.244 122.378L285.762 134.16Z" fill="#020B23" />
    <path d="M368.752 175.612L366.344 179.396C364.71 181.89 361.442 182.664 358.862 181.116L317.066 154.714C314.572 153.08 313.798 149.812 315.346 147.232L317.754 143.448C319.388 140.954 322.656 140.18 325.236 141.728L367.032 168.13C369.612 169.678 370.3 173.032 368.752 175.612Z" fill="white" />
    <path d="M361.786 182.492C360.668 182.492 359.55 182.148 358.604 181.546L316.808 155.144C313.97 153.424 313.11 149.64 314.916 146.888C314.916 146.888 314.916 146.888 314.916 146.802L317.324 143.018C319.13 140.18 322.828 139.32 325.666 141.126L367.462 167.528C370.3 169.248 371.16 173.032 369.354 175.784C369.354 175.784 369.354 175.784 369.354 175.87L366.86 179.74C366 181.116 364.624 182.062 363.076 182.406C362.646 182.492 362.216 182.578 361.786 182.492ZM322.312 141.556C320.678 141.556 319.13 142.416 318.27 143.792L315.862 147.576C314.486 149.812 315.088 152.736 317.324 154.112L359.12 180.514C361.356 181.89 364.28 181.288 365.656 179.052L368.064 175.268C369.44 173.032 368.838 170.108 366.602 168.732L324.806 142.33C324.118 141.814 323.258 141.556 322.312 141.556Z" fill="#020B23" />
    <path d="M225.046 87.548C213.092 106.554 218.768 131.666 237.774 143.62C256.78 155.574 281.892 149.898 293.846 130.892C305.8 111.886 300.124 86.774 281.118 74.82C262.112 62.866 237 68.542 225.046 87.548Z" fill="white" />
    <path d="M259.36 150.586C236.57 150.586 218.08 132.096 218.08 109.306C218.08 101.48 220.316 93.826 224.444 87.29C236.656 68.026 262.112 62.264 281.376 74.476C300.64 86.688 306.402 112.144 294.19 131.408C286.708 143.362 273.55 150.586 259.36 150.586ZM225.562 87.892C213.78 106.554 219.37 131.322 238.032 143.104C256.694 154.886 281.462 149.296 293.244 130.634C305.026 111.972 299.436 87.204 280.774 75.422C271.83 69.746 260.908 67.854 250.588 70.262C240.268 72.584 231.238 78.948 225.562 87.892Z" fill="#020B23" />
    <path d="M276.874 82.3576C291.924 91.8176 296.396 111.684 286.936 126.734C277.476 141.784 257.61 146.256 242.56 136.796C227.51 127.336 223.038 107.47 232.498 92.4196C241.958 77.3696 261.824 72.8976 276.874 82.3576Z" fill="white" />
    <path d="M260.055 122.247C263.167 122.116 265.447 116.259 265.148 109.165C264.85 102.071 262.086 96.4261 258.974 96.5571C255.863 96.688 253.582 102.545 253.881 109.639C254.18 116.733 256.944 122.378 260.055 122.247Z" fill="#020B23" />
    <path d="M259.36 142.072C241.214 141.986 226.594 127.28 226.594 109.22C226.68 91.074 241.386 76.454 259.446 76.454C265.638 76.454 271.658 78.26 276.904 81.528C292.212 91.16 296.856 111.456 287.138 126.764C281.204 136.31 270.626 142.158 259.36 142.072ZM259.446 77.744C241.988 77.658 227.884 91.762 227.798 109.22C227.712 126.678 241.816 140.782 259.274 140.868C276.732 140.954 290.836 126.85 290.922 109.392C290.922 98.556 285.418 88.408 276.216 82.646C271.228 79.464 265.38 77.744 259.446 77.744Z" fill="#D1D3D4" />
    <path d="M222.638 254.216L300.984 268.578L278.624 373.756L197.096 364.296L222.638 254.216Z" fill="#020B23" />
    <path d="M278.624 374.444L197.01 364.984C196.666 364.984 196.408 364.64 196.408 364.296V364.21L221.95 254.13C222.036 253.786 222.38 253.614 222.724 253.614L301.07 267.976C301.242 267.976 301.414 268.062 301.5 268.234C301.586 268.406 301.586 268.578 301.586 268.75L279.226 373.928C279.14 374.186 278.968 374.358 278.624 374.444ZM197.87 363.78L278.108 373.068L300.21 269.18L223.154 255.076L197.87 363.78Z" fill="#020B23" />
    <path d="M244.138 261.096L247.75 252.668L281.72 258.688V267.546L244.138 261.096Z" fill="#020B23" />
    <path d="M281.72 268.234H281.634L244.052 261.784C243.708 261.698 243.45 261.354 243.536 261.01V260.924L247.148 252.496C247.234 252.238 247.578 252.066 247.836 252.152L281.806 258.172C282.15 258.258 282.322 258.516 282.322 258.774V267.632C282.322 267.804 282.236 267.976 282.064 268.148C281.978 268.234 281.892 268.234 281.72 268.234ZM244.998 260.666L281.032 266.858V259.29L248.18 253.442L244.998 260.666Z" fill="#020B23" />
    <path d="M339.942 164.002C341.318 163.314 341.662 161.594 341.748 160.046C341.834 157.122 341.318 154.284 340.286 151.532L345.36 162.626C346.306 164.776 347.338 167.012 349.058 168.646C350.778 170.28 353.444 171.14 355.594 170.194C358.776 168.818 359.292 164.518 358.862 161.164C358.432 157.982 357.658 154.886 356.368 151.876C355.25 149.296 353.702 146.716 351.294 145.254C348.886 143.792 345.446 143.62 343.468 145.598C341.318 142.244 336.674 136.138 331.858 137.342C328.676 138.116 329.02 139.922 329.708 142.846C330.482 146.888 334.266 166.668 339.942 164.002Z" fill="white" />
    <path d="M353.702 171.226C351.81 171.14 350.004 170.366 348.628 169.076C346.822 167.356 345.704 164.948 344.758 162.884L342.264 157.552C342.35 158.412 342.35 159.186 342.35 160.046C342.264 162.368 341.49 163.916 340.2 164.518C339.34 164.948 338.394 164.948 337.534 164.518C333.664 162.626 331.084 152.564 329.278 144.05L329.02 143.018C328.418 140.266 327.816 137.686 331.686 136.74C335.384 135.794 339.512 138.546 343.64 144.652C346.048 142.932 349.488 143.362 351.638 144.738C353.788 146.114 355.508 148.35 356.97 151.618C358.26 154.628 359.12 157.81 359.55 161.078C359.808 162.884 360.238 168.904 355.938 170.796C355.164 171.14 354.476 171.312 353.702 171.226ZM340.286 150.93C340.544 150.93 340.802 151.102 340.888 151.274L345.962 162.368C346.822 164.346 347.854 166.582 349.574 168.13C351.036 169.506 353.358 170.452 355.422 169.592C358.862 168.044 358.432 162.798 358.26 161.164C357.83 158.068 357.056 154.972 355.766 152.048C354.476 148.952 352.842 146.888 350.95 145.77C349.058 144.652 345.79 144.222 343.898 146.028C343.726 146.2 343.554 146.2 343.382 146.2C343.21 146.2 343.038 146.114 342.952 145.942C340.2 141.728 336.072 136.998 332.03 137.944C329.536 138.546 329.622 139.664 330.31 142.674L330.568 143.706C331.514 148.092 334.352 161.422 338.136 163.314C338.652 163.572 339.168 163.572 339.684 163.314C340.802 162.798 341.06 161.164 341.146 159.96C341.232 157.122 340.716 154.37 339.77 151.704C339.598 151.36 339.77 151.016 340.114 150.844C340.114 150.93 340.2 150.93 340.286 150.93Z" fill="#020B23" />
    <path d="M232.356 303.064C231.754 296.184 209.48 305.558 209.48 305.558C209.48 305.558 215.758 291.282 212.404 288.616C209.05 285.95 197.698 303.236 197.698 303.236C197.698 303.236 181.874 305.73 168.888 305.558L166.91 337.464C198.902 335.142 219.628 334.282 220.574 332.218C222.466 328.09 232.958 309.858 232.356 303.064Z" fill="white" />
    <path d="M166.824 338.066C166.48 338.066 166.222 337.808 166.222 337.464L168.2 305.472C168.2 305.128 168.458 304.956 168.802 304.956C180.67 305.128 195.118 303.064 197.268 302.72C198.73 300.484 206.9 288.53 211.114 287.842C211.716 287.756 212.232 287.842 212.748 288.186C215.844 290.594 212.146 300.742 210.512 304.526C214.64 302.892 227.282 298.248 231.496 300.742C232.356 301.258 232.872 302.118 232.958 303.064C233.474 308.912 226.508 322.07 222.81 329.208C222.036 330.67 221.434 331.788 221.09 332.562C220.23 334.454 211.114 335.142 184.798 336.948C179.294 337.206 173.36 337.55 166.824 338.066ZM169.404 306.074L167.512 336.776C173.704 336.346 179.466 335.916 184.798 335.572C203.89 334.282 219.026 333.25 220.058 331.874C220.402 331.186 221.004 329.982 221.778 328.52C225.218 322.07 232.27 308.568 231.754 303.064C231.754 302.462 231.41 301.946 230.894 301.688C227.282 299.538 214.382 304.096 209.738 306.074C209.48 306.16 209.222 306.074 209.05 305.902C208.878 305.73 208.878 305.472 208.964 305.214C211.372 299.882 214.21 290.68 212.06 288.96C211.888 288.788 211.63 288.788 211.372 288.874C208.104 289.39 200.708 299.624 198.214 303.494C198.128 303.666 197.956 303.752 197.784 303.752C197.612 303.752 182.132 306.246 169.404 306.074Z" fill="#020B23" />
  </svg>
</template>
