import type { AppInfo } from '~/types'

// TODO: set app info
export const APP_INFO: AppInfo = {
  name: 'Multiplai Interviews',
  shortName: 'Multiplai Interviews AI',
  description: 'Multiplai Interviews AI is a platform for conducting AI-powered interviews.',
  ogImageLink: 'og.png',
}

export const STORAGE_KEY_USER_SETTINGS = 'multiplai-user-settings'
export const STORAGE_KEY_ROOM_SETTINGS = 'multiplai-room-settings'
export const STORAGE_KEY_CURRENT_USER_HANDLE = 'multiplai-user-handle'
export const STORAGE_KEY_ORGANIZATIONS = 'multiplai-organizations'

export * from './faqs'
export * from './rating'
