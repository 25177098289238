import revive_payload_client_dtuLTskdME from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._mmzahmycezjgmj3laz4w5mtbve/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_hsMEbpWQQH from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._mmzahmycezjgmj3laz4w5mtbve/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_PjJB0fcssz from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._mmzahmycezjgmj3laz4w5mtbve/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_pW8WapdkMM from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._mmzahmycezjgmj3laz4w5mtbve/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_vkhYXnXAtq from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._mmzahmycezjgmj3laz4w5mtbve/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_DKNg7s3IYR from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._mmzahmycezjgmj3laz4w5mtbve/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_FprekVVmgG from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._mmzahmycezjgmj3laz4w5mtbve/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_q6WeiPRc3E from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.25.0_typescript@5.6.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_IquY0dsSvn from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._mmzahmycezjgmj3laz4w5mtbve/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_yXm34t5y6f from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.25.0_webpack-sources@3.2.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import unocss_MzCDxu9LMj from "/vercel/path0/.nuxt/unocss.mjs";
import theme_client_ZVSoAio4kE from "/vercel/path0/node_modules/.pnpm/@una-ui+nuxt-edge@0.28.0-beta.1-28856191.234d7d8_@unocss+preset-wind@0.63.6_@unocss+webpack@0_2perihtvtxr6vbw6zs2vomq4a4/node_modules/@una-ui/nuxt-edge/dist/runtime/plugins/theme.client.js";
import analytics_client_mtqVw3YRXk from "/vercel/path0/plugins/analytics.client.ts";
export default [
  revive_payload_client_dtuLTskdME,
  unhead_hsMEbpWQQH,
  router_PjJB0fcssz,
  payload_client_pW8WapdkMM,
  navigation_repaint_client_vkhYXnXAtq,
  check_outdated_build_client_DKNg7s3IYR,
  chunk_reload_client_FprekVVmgG,
  plugin_vue3_q6WeiPRc3E,
  components_plugin_KR1HBZs4kY,
  prefetch_client_IquY0dsSvn,
  plugin_client_yXm34t5y6f,
  unocss_MzCDxu9LMj,
  theme_client_ZVSoAio4kE,
  analytics_client_mtqVw3YRXk
]